img {
  height: 50px;
  width: 110px;
}
#collasible-nav-dropdown {
  color: navy;
}

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) and (max-width: 479px) {
  #responsive-navbar-nav.menu {
    color: black;
    font-weight: 500;
    margin-left: 30rem;
    font-size: 17px;
    display: flex;
    justify-content: flex-end;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) and (max-width: 767px) {
  #responsive-navbar-nav.menu {
    color: navy;
    font-weight: 500;
    margin-left: 30rem;
    font-size: 17px;
    display: flex;
    justify-content: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width: 991px) {
  #responsive-navbar-nav.menu {
    color: navy;
    font-weight: 500;
    margin-left: 30rem;
    font-size: 17px;
    display: flex;
    justify-content: center;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) and (max-width: 1199px)  {
  #responsive-navbar-nav.menu#collasible-nav-dropdown {
    color: navy;
    font-weight: 500;
    margin-left: 45rem;
    font-size: 17px;
    display: flex;
    justify-content: center;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .menu {
    color: navy;
    font-weight: 500;
    margin-left: 45rem;
    font-size: 17px;
    display: flex;
    justify-content: center;
  }
}