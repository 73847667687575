@import url("https://fonts.googleapis.com/css2?family=Handlee&family=PT+Sans&family=Poppins&family=Roboto:ital,wght@1,700&display=swap");

/* .desc {
  line-height: 2rem;
} */

.call-center {
  width: 530px;
  height: 300px;
  border-radius: 30px;
}

.ag{
  color: #1F9227;
  font-family: "Yanone Kaffeesatz";
  font-weight: 700;
}

.agro {
  color: #023368;
  font-family: "Yanone Kaffeesatz";
  font-weight: 700;
}

.future {
  font-family: "Yanone Kaffeesatz";
  font-weight: 600;
}
