.underline {
  height: 2px;
  width: 4rem;
  background-color: #1c78ba;
  margin-top: 5px;
  margin-bottom: 10px;
}

#head {
  color: #023368;
}
