/* .footer {
  background-color: #023368;
  color: white;
} */
.link i.fab {
  color: navy !important;
}
i {
  color: navy;
}
.agro {
  margin-left: 5px;
}
#footer-email-btn {
  color: navy;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 3px;
  margin: 5px;
}

.copy {
  color: black;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 4px;
  margin-right: 15px;
}
